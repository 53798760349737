<template>
  <div :class="containerClassesStepper">
    <el-steps
      :active="state.position"
      v-bind="stepperProps"
      :align-center="centerAligned"
    >
      <el-step
        v-for="step in steps"
        :key="step.title"
        :title="step.title"
        :description="step.description"
      />
    </el-steps>
  </div>

  <div :class="containerClassesContent">
    <slot
      :data="{
        setStep
      }"
    />
  </div>

  <div :class="['d-flex mt-2', containerClassesAction]">
    <slot
      name="actions"
      :data="{
        moveStep
      }"
    >
      <button
        type="button"
        :class="[btnBackClasses]"
        :disabled="!state.position || loading || preventBack"
        @click="moveStep(false)"
      >
        {{ btnBackText }}
      </button>
      <button
        type="button"
        :class="['d-flex', btnNextClasses]"
        :disabled="loading || state.finished || preventForward"
        @click="moveStep"
      >
        <Spinner
          v-if="loading && state.finished"
          classes="me-1"
          size="18"
        />
        {{ state.position === maxStepSize ? btnNextFinishText : btnNextText }}
      </button>
    </slot>
  </div>

</template>

<script>
import { computed, reactive, watch } from 'vue';
import Spinner from '@/components/Util/Spinner';

export default {
  name: 'Stepper',
  components: { Spinner },
  emits: ['update:modelValue', 'complete', 'beforeStep'],
  props: {
    modelValue: {
      type: Number
    },
    steps: {
      type: Array,
      validator: (value) => {
        if (value.length === 0) throw new Error('Steps can\'t be empty');
        if (typeof value[0] !== 'object') throw new Error('Each step must be an object');
        if (!value[0].hasOwnProperty('title')) throw new Error('Each step must \'title\' property');

        return true;
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    preventBack: {
      type: Boolean,
      default: false
    },
    preventForward: {
      type: Boolean,
      default: false
    },
    centerAligned: {
      type: Boolean,
      default: false
    },
    stepperProps: {
      type: Object,
      default: () => {}
    },

    // customization
    containerClassesStepper: {
      type: String,
      default: ''
    },
    containerClassesContent: {
      type: String,
      default: ''
    },
    containerClassesAction: {
      type: String,
      default: ''
    },
    btnBackClasses: {
      type: String,
      default: 'btn btn-outline-secondary me-2'
    },
    btnBackText: {
      type: String,
      default: 'Back'
    },
    btnNextClasses: {
      type: String,
      default: 'btn btn-outline-primary'
    },
    btnNextText: {
      type: String,
      default: 'Next'
    },
    btnNextFinishText: {
      type: String,
      default: 'Finish'
    },
  },

  setup (props, { emit }) {
    const state = reactive({
      position: props.modelValue,
      onMaxStep: false,
      finished: false
    });

    // update parent as v-model
    watch(() => state.position, nv => {
      emit('update:modelValue', nv);
    });

    const maxStepSize = computed(() => (props.steps.length === 0) ? 0 : (props.steps.length - 1));

    async function changeStepPosition (stepPosition) {

      await emit('beforeStep', {
        from: props.modelValue,
        to: stepPosition,
        isForward: stepPosition > props.modelValue
      });

      state.position = stepPosition;

      if (state.onMaxStep) {

        emit('complete', stepPosition);
        state.finished = true;

      } else {
        state.finished = false;
      }
    }

    const moveStep = (forward = true) => {

      // dont fo less than 0
      if (forward === false) {

        if (props.preventBack) return;

        const newPosition = (state.position <= 0)
          ? 0
          : (state.position - 1)
        ;

        state.onMaxStep = false;
        changeStepPosition(newPosition);

        return;
      }

      if (props.preventForward) return;

      // don't to more than max steps available
      const newPosition = (state.position >= maxStepSize.value)
        ? maxStepSize.value
        : (state.position + 1)
      ;


      changeStepPosition(newPosition);
      state.onMaxStep = (state.position === maxStepSize.value);
    };

    const setStep = (stepPosition) => {

      if (typeof stepPosition !== 'number') throw new Error('Step position must be integer');

      if (stepPosition < 0 || stepPosition > maxStepSize.value) throw new Error(`Invalid step position '${stepPosition}' valid 0-${maxStepSize.value}`);

      changeStepPosition(stepPosition);
    };

    return {
      state,
      maxStepSize,
      moveStep,
      setStep
    };
  }
};
</script>

<style lang="scss">
:root {
  --el-color-primary: #007ae1;
}
.el-step__title.is-process {
  font-weight: 500;
}
</style>
